<template>
  <div>
    <Row class="text-center" style="margin-top: -8px;">
      <i-col span="24">
        <RadioGroup v-model="rateType" type="button" button-style="solid">
            <Radio :label="1">去化率</Radio>
            <Radio :label="2">资源预约成交率</Radio>
            <Radio :label="3">预售率</Radio>
        </RadioGroup>
      </i-col>
    </Row>
    <div style="width:100%;height:250px;" id="listAssetProductLineChartReport"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { getStorage } from '@/utils/storage'
import { listAssetProductLineChart } from '@/api/dw/asset'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,

      rateType: 1, // 查询类型：1：去化率；2：成交率；3：预售率；
      dataSourcePie: null
    }
  },
  mounted () {
    this.loadReportData()
  },
  methods: {
    loadReportData () {
      const postData = {
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        assetId: this.assetId,
        companyId: this.companyId,
        rateType: this.rateType,
        productIds: this.productIds.length > 0 ? this.productIds.join(',') : null
      }

      listAssetProductLineChart(postData).then(res2 => {
        let labels = []
        let seriesData1 = []
        let seriesData2 = []
        let seriesName1 = ''
        let seriesName2 = ''

        if (res2.length > 0) {
          seriesName1 = res2[0].s
          labels = res2.filter(x => x.s === seriesName1).map(x => x.x)
          seriesData1 = res2.filter(x => x.s === seriesName1).map(x => x.y)

          seriesName2 = res2.filter(x => x.s !== seriesName1)[0].s
          seriesData2 = res2.filter(x => x.s !== seriesName1).map(x => x.y)
        }

        this.initReportChart(labels, seriesName1, seriesData1, seriesName2, seriesData2)
      })
    },
    initReportChart (labels, seriesName1, seriesData1, seriesName2, seriesData2) {
      if (this.dataSourcePie != null && this.dataSourcePie !== '' && this.dataSourcePie !== undefined) {
        this.dataSourcePie.dispose()
      }

      this.$nextTick(() => {
        // 防止页面被切换，元素被移除
        if (document.getElementById('listAssetProductLineChartReport')) {
          this.dataSourcePie = echarts.init(document.getElementById('listAssetProductLineChartReport'))

          const option = {
            color: ['#3A4DE9', '#91CC75'],
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: labels,
              axisLabel: {
                color: '#c5c8ce'
              }
            },
            legend: {
              data: [seriesName1, seriesName2],
              right: 50,
              textStyle: {
                color: this.reportFontColor
              }
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            grid: {
              top: 50,
              left: 50,
              right: 30,
              bottom: 25
            },
            yAxis: {
              type: 'value',
              splitLine: {
                lineStyle: {
                  opacity: 0.1
                }
              }
            },
            series: [
              {
                name: seriesName1,
                data: seriesData1,
                type: 'line',
                smooth: true,
                label: {
                  show: true,
                  position: 'insideLeft',
                  valueAnimation: true,
                  color: this.reportFontColor,
                  rich: {
                    color: this.reportFontColor
                  }
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: 'rgba(58,77,233,0.8)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(58,77,233,0.3)'
                    }
                  ])
                }
              },
              {
                name: seriesName2,
                data: seriesData2,
                type: 'line',
                smooth: true,
                label: {
                  show: true,
                  position: 'top',
                  valueAnimation: true,
                  color: this.reportFontColor,
                  rich: {
                    color: this.reportFontColor
                  }
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: 'rgba(145,204,117,0.8)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(145,204,117,0.3)'
                    }
                  ])
                }
              }
            ]
          }

          this.dataSourcePie.setOption(option, true)
          const that = this
          window.addEventListener('resize', function () {
            that.dataSourcePie.resize()
          })
        }
      })
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    companyId () {
      return this.$store.state.situationWeb.companyId
    },
    assetId () {
      return this.$store.state.situationWeb.assetId
    },
    productIds () {
      return this.$store.state.situationWeb.productIds
    },
    beignUpdateBottomAndRight () {
      return this.$store.state.situationWeb.beignUpdateBottomAndRight
    },
    reportFontColor () {
      // 获取并设置当前页面的主题
      const theme = getStorage('oohforce-workbench-theme') || 'dark'
      return theme === 'dark' ? '#fff' : '#000'
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.loadReportData()
      }
    },
    rateType () {
      this.loadReportData()
    },
    beignUpdateBottomAndRight () {
      this.loadReportData()
    }
  }
}
</script>
